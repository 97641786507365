import React from "react"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { removeTags } from '~c/utils/base'

import Layout from "~l/layout"
import Breadcrumbs from "~c/partials/breadcrumbs/breadcrumbs"

import "./article.scss"

interface Props {
  data: any
  pageContext: {
    title: string
    tags: []
    image: any
    author_avatar: any
    author: string
    date_published: Date
    date_modified: Date
    html: string
    shortscription: string
  }
  location: {
    hash: string
    host: string
    hostname: string
    href: string
    key: string
    origin: string
    pathname: string
    port: string
    protocol: string
    search: string
    state: string
  }
}

export default ({ data, pageContext, location }: Props) => {
  const {
    author,
    date_published,
    date_modified,
    tags,
    title,
    image,
    html,
    author_avatar,
    shortscription,
  } = pageContext
  const { siteMetadata } = data.site

  const shortModifiedDate = new Date(date_modified).toDateString()
  const imageData = getImage(image)
  const authorAvaratData = getImage(author_avatar)

  const postImage = getImage(image)

  return (
    <Layout
      meta={{
        title: removeTags(title),
        description: shortscription,
        canonical: location.pathname,
        schema: `
        {
          "@context": "http://schema.org",
          "@type": "Article",
          "headline": "${title}",
          "image": {
            "@type": "ImageObject",
            "url": "${siteMetadata.siteUrl}${postImage?.images.fallback?.src}"
          },
          "datePublished": "${date_published}",
          "dateModified": "${date_modified}",
          "author": {
            "@type": "Person",
            "name": "${siteMetadata.author}",
            "jobTitle": "${siteMetadata.job_title}"
          },
          "description": "${shortscription}",
          "keywords": "${tags.join(", ").toLowerCase()}"
        }`,
        siteName: 'Blog',
        openGraph: {
          type: "article",
          image: postImage?.images.fallback?.src,
        },
      }}
    >
      <article className="view view-article">
        <div className="view-article__title">
          <div className="view-content view-content--medium">
            <Breadcrumbs
              location={location}
              parentClassName="text-center"
            />
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          </div>
        </div>
        <div className="view-article__content">
          <div className="view-content view-content--medium bg-white p-36">
            <div className="view-article__description">
              <div className="view-article__description-image">
                {imageData ? (
                  <GatsbyImage image={imageData} alt={title} />
                ) : null}
              </div>
              <div className="view-article__description-meta">
                <div className="view-article__description-author">
                  <div className="view-article__description-author-image">
                    {authorAvaratData ? (
                      <GatsbyImage image={authorAvaratData} alt={title} />
                    ) : null}
                  </div>
                  <div className="view-article__description-author-name">
                    Written by <strong>{author}</strong>
                  </div>
                </div>
                <div className="view-article__description-tags">
                  <div className="view-article__description-tags-icon"></div>
                  <strong>{tags.join(", ")}</strong>
                </div>
                <div className="view-article__description-date">
                  <div className="view-article__description-date-icon"></div>
                  <strong>{shortModifiedDate}</strong>
                </div>
              </div>
            </div>

            <div className="view-article__text">
              <div
                className="view-text"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        job_title
        author
      }
    }
  }
`
